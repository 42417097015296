import React, {Component} from 'react'
import {connect} from "react-redux";

import { checkoutAction, clearSubmittedOrder } from '../../actions/checkoutActions'
import {
    normalizeCartItemsByColor,
    calculateTotalPrice,
    calculateTopSeason,
    calculateOrderType
} from '../../utils/cartUtils'

import Api from '../../controllers/Api'

import {addLivePricesToProduct, getCurrencySymbol, getLivePrices} from "../../utils/priceUtils";

import CheckoutForm from '../Checkout/CheckoutForm'

import './CheckoutPage.scss'
import Loader from "../Common/Loader";

class CheckoutPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cartProducts: [],
            isPreorder: false
        }

    }

    componentDidMount() {
        const { cart: { cartItems }, history, customer: { activeCustomer } } = this.props

        if(!cartItems.length || !activeCustomer) {
            history.push('/app')
        }

        //Get cart products
        const productIds = cartItems.map(cartItem => cartItem.productId)

        Api.product.getMultiple(productIds).then(cartProducts => {
            let isPreorder = false

            for(let i = 0; i < cartProducts.length; i++) {
                if(cartProducts[i].markedAsPreorder) {
                    isPreorder = true;
                    break;
                }
            }

            this.setState({
                cartProducts,
                isPreorder
            })
        }).then(() => {
            //Fetch live prices
            getLivePrices(this.state.cartProducts, activeCustomer, false).then((livePrices) => {
                if(livePrices && livePrices.length) {
                    const productsWithLivePrices = this.state.cartProducts.map(product => {
                        return addLivePricesToProduct(product, livePrices)
                    })

                    this.setState({
                        cartProducts: productsWithLivePrices
                    })
                }
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { checkout: { submittedOrderId, submittedButFailed }, history, clearSubmittedOrder } = this.props

        if(submittedOrderId) {
            //Redirect to thank you with order id
            history.push(`/app/thank-you/${submittedOrderId}`)
            clearSubmittedOrder()
        }else if(submittedButFailed) {
            //Redirect to thank you without order id
            history.push('/app/thank-you')
            clearSubmittedOrder()
        }
    }

    render() {
        const {
            auth: {activeUser, userSalesmen},
            customer: { activeCustomer },
            product: {products, variationPrices},
            cart: {cartCount, cartItems},
            order,
            checkoutAction,
            season: { seasons },
            settings: { settings },
            checkout: { failedOrderPayload },
            history,
        } = this.props

        const { cartProducts } = this.state

        if(!cartProducts || !cartProducts.length) {
            return <Loader />
        }

        if(failedOrderPayload && Object.keys(failedOrderPayload).length) {
            history.push('/app/cart')
        }

        const cartItemsByColor = normalizeCartItemsByColor(cartProducts, cartItems, variationPrices)
        const cartTotalPrice = calculateTotalPrice(cartItemsByColor, getCurrencySymbol(activeCustomer))
        const cartTotalPriceFlat = calculateTotalPrice(cartItemsByColor, getCurrencySymbol(activeCustomer), false)

        console.log(cartItems)
        console.log(cartProducts)
        console.log(seasons)
        console.log(settings)

        const topSeasons = calculateTopSeason(cartItemsByColor, seasons, settings, this.state.isPreorder)
        const orderType = calculateOrderType(topSeasons)

        return <div className="checkout-page">
            <CheckoutForm
                loadedOrder={order}
                activeUser={activeUser}
                userSalesmen={userSalesmen}
                customer={activeCustomer}
                cartTotalQty={cartCount}
                cartTotalPrice={cartTotalPrice}
                cartTotalPriceFlat={cartTotalPriceFlat}
                checkoutAction={checkoutAction}
                topSeasons={topSeasons}
                orderType={orderType}
                isPreorder={this.state.isPreorder}
            />
        </div>
    }
}

const mapStateToProps = ({ auth, cart, customer, product, checkout, order, season, settings }) => {
    return {
        order,
        auth,
        cart,
        customer,
        product,
        checkout,
        season,
        settings
    }
}

export default connect(mapStateToProps, { checkoutAction, clearSubmittedOrder })(CheckoutPage)
