import Api from "../controllers/Api";

export const TYPES = {
    SAVE_SETTINGS: 'SAVE_SETTINGS',
    FETCHED_SETTINGS: 'FETCHED_SETTINGS',
    SAVE_HOMEPAGE_SETTINGS: 'SAVE_HOMEPAGE_SETTINGS',
    FETCHED_HOMEPAGE_SETTINGS: 'FETCHED_HOMEPAGE_SETTINGS',
    FETCHED_HEADER_SETTINGS: 'FETCHED_HEADER_SETTINGS',
    SAVE_HEADER_SETTINGS: 'SAVE_HEADER_SETTINGS',
}

export const getSettings = () => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.get()

            dispatch({
                type: TYPES.FETCHED_SETTINGS,
                payload: settings
            })
        } catch (e) {
        }
    }
}

export const getHomepageSettings = () => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.getHomepage()

            dispatch({
                type: TYPES.FETCHED_HOMEPAGE_SETTINGS,
                payload: settings
            })
        } catch (e) {
        }
    }
}

export const getHeaderSettings = () => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.getHeader()

            dispatch({
                type: TYPES.FETCHED_HEADER_SETTINGS,
                payload: settings
            })
        } catch (e) {
        }
    }
}

export const updateMenuAction = (menuItems = []) => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.saveMenu(menuItems)

            alert('Menu Saved')

            dispatch({
                type: TYPES.FETCHED_SETTINGS,
                payload: settings
            })
        } catch (e) {
            alert('Failed to save menu')
        }
    }
}

export const updateMenuActionCZ = (menuItems = []) => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.saveMenuCZ(menuItems)

            alert('Menu Saved')

            dispatch({
                type: TYPES.FETCHED_SETTINGS,
                payload: settings
            })
        } catch (e) {
            alert('Failed to save menu')
        }
    }
}

export const updateMenuActionSimone = (menuItems = []) => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.saveMenuSimone(menuItems)

            alert('Menu Saved')

            dispatch({
                type: TYPES.FETCHED_SETTINGS,
                payload: settings
            })
        } catch (e) {
            alert('Failed to save menu')
        }
    }
}

export const saveSettings = (settingsData) => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.save(settingsData)

            dispatch({
                type: TYPES.SAVE_SETTINGS,
                payload: settings
            })

            alert('Settings Saved')

        } catch (e) {

            alert('Failed to save settings')

            if(e.payload) {
                alert(e.payload)
            }
            console.log(e)
        }
    }
}

export const saveHomepageSettings = (settingsData) => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.saveHomepage(settingsData)

            dispatch({
                type: TYPES.SAVE_HOMEPAGE_SETTINGS,
                payload: settings
            })

            alert('Settings Saved')

        } catch (e) {

            alert('Failed to save settings')

            if(e.payload) {
                alert(e.payload)
            }
            console.log(e)
        }
    }
}

export const saveHeaderSettings = (settingsData) => {
    return async (dispatch, getState) => {
        try {
            const settings = await Api.settings.saveHeader(settingsData)

            dispatch({
                type: TYPES.SAVE_HEADER_SETTINGS,
                payload: settings
            })

            alert('Settings Saved')

        } catch (e) {

            alert('Failed to save settings')

            if(e.payload) {
                alert(e.payload)
            }
            console.log(e)
        }
    }
}

