import React, {Component} from 'react'
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";

import {getCustomersAction} from '../../actions/customerActions'
import {loadOrderAction, deleteOrderAction, clearSavedOrder} from '../../actions/orderActions'
import {ReactComponent as OrderSvg} from "../../components/assets/order-1.svg";
import {ReactComponent as MyAccountSvg} from "../../components/assets/agent.svg";

import AccountSidebar from '../AccountSidebar/AccountSidebar'
import AccountSidebarItem from '../AccountSidebar/AccountSidebarItem'
import AccountPanel from "../Panels/AccountPanel";
import CustomerAccessPage from "../Customer/CustomerAccessPage"
import CustomersBalancesPage from "./Customer/CustomersBalancesPage"
import UserOrdersPanel from "../Panels/UserOrdersPanel";
import CustomersPanel from "../Panels/CustomersPanel";
import CustomersCreateForm from "../Forms/CustomersCreateForm";
import SalesmanSeasonsCompare from '../Salesman/Infos/SalesmanSeasonsCompare'
import SalesmanTurnovers from '../Salesman/Infos/SalesmanTurnovers'

import {ReactComponent as SalesmanAccessSvg} from '../assets/salesman/salesmanAccess.svg';
import {ReactComponent as SalesmanCustomersSvg} from '../assets/salesman/salesmanCustomers.svg';
import {ReactComponent as SalesmanPotentialsSvg} from '../assets/salesman/salesmanPotentials.svg';
import {ReactComponent as SalesmanStatisticsSvg} from '../assets/salesman/salesmanStatistics.svg';
import { withTranslation } from 'react-i18next';


import './AccountPage.scss'
import Api from "../../controllers/Api";

class AccountPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customersBalances: [],
            fetchingBalances: false,
        }
    }

    componentDidMount() {
    }

    fetchCustomersBalances() {
        const {
            auth: {activeUser},
        } = this.props
    }

    render() {
        const { t } = this.props;
        const {
            auth: {activeUser},
            customer: {customers},
            loadOrderAction,
            deleteOrderAction,
            history
        } = this.props

        return <div className={`account-page role-${activeUser.role}`}>
            <div className="account-sidebar">
                <AccountSidebar>
                    <AccountSidebarItem
                        title={t('My Account')}
                        link="/app/account"
                        icon={<MyAccountSvg/>}
                    />
                    <AccountSidebarItem
                        title={t('Customers')}
                        link="/app/account/customers"
                        icon={<SalesmanCustomersSvg/>}
                    />
                    <AccountSidebarItem
                        title={t('Saved Orders')}
                        link="/app/account/orders"
                        icon={<OrderSvg/>}
                    />
                    <AccountSidebarItem
                        title={t('Customer Access')}
                        link="/app/account/customers/access"
                        icon={<SalesmanAccessSvg/>}
                    />
                    {/*<AccountSidebarItem
                        title="Potentials"
                        link="/app/account/customers/potential"
                        icon={<SalesmanPotentialsSvg/>}
                    />*/}
                    <AccountSidebarItem
                        title={t('InformationStatistics')}
                        link="/app/salesman/information"
                        icon={<SalesmanStatisticsSvg/>}
                    />
                </AccountSidebar>
            </div>
            <div className="account-panel">
                <Route path="/app/account" exact component={() => <AccountPanel user={activeUser}/>}/>
                <Route path="/app/account/orders" exact component={() =>
                    <UserOrdersPanel
                        clearSavedOrder={clearSavedOrder}
                        loadOrderAction={(orderId) => {
                            loadOrderAction(orderId)
                            history.push('/app')
                        }
                        }
                        user={activeUser}
                    />}
                />
                <Route path="/app/account/customers" exact
                       component={(props) => <CustomersPanel {...props} customers={customers}/>}/>
                <Route path="/app/account/customers/create" exact
                       component={(props) => <CustomersCreateForm {...props} />}/>
                <Route path="/app/account/customers/access" exact component={CustomerAccessPage}/>
                <Route
                    path="/app/account/customers/potential"
                    exact
                    component={(props) => <CustomersPanel
                        {...props}
                        potentialCustomer={true}
                        customers={(customers && customers.length) ? customers.filter(customer => customer.customer[0].potential) : []}
                    />}
                />
                <Route path="/app/account/customers/potential/create" exact
                       component={(props) => <CustomersCreateForm potentialCustomer={true} {...props} />}/>
            </div>
        </div>
    }
}

const mapStateToProps = ({auth, customer}) => {
    return {
        auth,
        customer
    }
}

export default withTranslation()(connect(mapStateToProps, {
    getCustomersAction,
    loadOrderAction,
    deleteOrderAction,
    clearSavedOrder,
})(AccountPage))

