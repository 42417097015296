import React, {useState} from "react";
import ProductImages from "../../Product/ProductImages";
import ColorsList from "../../Product/ColorsList";
import SizesList from "../../Product/SizesList";
import Button from "../../Button";
import QuickView from "../../Quickview/QuickView";
import BottomBar from "../../Common/BottomBar";
import WishlistButton from "../../Product/WishlistButton";
import {getCurrencySymbol, printProductDisplayPrice} from "../../../utils/priceUtils";
import ProductMeta from "../../Product/ProductMeta";
import { useTranslation } from "react-i18next";

const findColorsAndSizes = (product) => {
    let sizes = new Set()
    let colors = {}

    for (let i = 0; i < product.variations.length; i++) {
        sizes.add(product.variations[i].sizeName)

        if (colors[product.variations[i].colorId]) {
            continue;
        }

        if( product.factory === 'Simone' ){
            const selectedSeason = localStorage.getItem("selectedSeason");
            if( selectedSeason !== product.variations[i].season && product.variations[i].season !== "0000" ){
                continue;
            }
        }

        colors[product.variations[i].colorId] = {
            name: product.variations[i].colorName,
            hex: product.variations[i].colorHex,
            code: product.variations[i].colorCode,
        }
    }

    return {
        sizes: Array.from(sizes),
        colors: Object.values(colors)
    }
}

const SimpleMode = ({
                        product,
                        activeCustomer,
                        activeUser,
                        variationPrices,
                        openQuickViewAction,
                        quickViewProductId,
                        isInWishlist,
                        wishlistToggleAction
}) => {
    const { t } = useTranslation();

    const [ selectedColor, setSelectedColor ] = useState(false)
    //Find colors and sizes
    const {colors, sizes} = findColorsAndSizes(product)

    let productImages = product.additionalImages.slice()
    productImages.unshift(product.image)

    //Find price and currency
    const {lowestPrice} = printProductDisplayPrice(product, variationPrices)

    let currencySymbol = getCurrencySymbol(activeUser);

    if(activeCustomer) {
        currencySymbol = getCurrencySymbol(activeCustomer);
    }

    //plus one for product videos
    let carouselItemsCount = ((productImages.length + 1))

    if(carouselItemsCount > 2) {
        carouselItemsCount = 3
    }

    productImages = productImages.slice().map(image => {
        return {
            url: image,
            type: 'image',
            visible: true
        }
    })

    if(selectedColor){
        productImages = productImages.map( image => {
            let visibility = false
            for( let x = 0 ; x < product.additionalImagesWithAtt.length ; x++ ){
                if( product.additionalImagesWithAtt[x].colorcode === selectedColor && image.url.includes(product.additionalImagesWithAtt[x].filename) ){
                    visibility = true
                }
            }
            image.visible = visibility
            return image
        } )
    }

    if( productImages.filter( tempProduct => tempProduct.visible === true ).length === 0 && selectedColor ){
        productImages = productImages.map( image => {
        image.visible = true
        return image
        })
    }

    if(!selectedColor){
        productImages = productImages.map( image => {
            let visibility = false
            for( let x = 0 ; x < product.additionalImagesWithAtt.length ; x++ ){
                if( colors.filter( color => color.code === product.additionalImagesWithAtt[x].colorcode ).length  && image.url.includes(product.additionalImagesWithAtt[x].filename) ){
                    visibility = true
                }
            }
            image.visible = visibility
            return image
        } )
    }

    console.log(product.additionalImagesWithAtt)
    console.log(productImages)
    console.log(colors)

    return <div className="product-simple-mode">
        {
            product.factory === 'Simone' ? <div className='simone-logo-container-product-page'>
                <img className='simone-logo-preorder-product' src="/simoneLogo.svg"/>
            </div> : ''
        }
        <div className="product-images">
            <ProductImages
                selectedColor={selectedColor}
                images={productImages}
                imagesWithDetail={product.additionalImagesWithAtt}
                videos={product.videos}
                infinite={false}
                slidesPerPage={carouselItemsCount}
                responsive={{
                    992: {
                        items: 3,
                    },
                }}
            />
        </div>
        <div className="product-bottom">
            <ProductMeta
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                extended={true}
                product={product}
                openQuickViewAction={openQuickViewAction}
                lowestPrice={lowestPrice}
                currencySymbol={currencySymbol}
            />
        </div>
        {quickViewProductId ? <QuickView presentationMode={true} productId={quickViewProductId} isSimoneProduct={product.factory === 'Simone'}/> : null}
        <BottomBar>
            <div className="product-info">
                <div className="product-name">
                    <strong>{product.code}</strong> - {product.titleEN}
                </div>
            </div>
            {product.variations[0].price ?
                <div className="product-controls">
                    <div className="product-price">
                        {`${currencySymbol}${product.variations[0].price.toFixed(2)}`}
                    </div>
                    <WishlistButton
                        handleWishlistClick={() => wishlistToggleAction(product._id, 'product')}
                        isInWishlist={isInWishlist}
                    />
                    <div className="product-quickview-button" onClick={() => openQuickViewAction(product._id, true)}>
                        <Button className="single-product-add-to-cart" title={t('Add to Order')}></Button>
                    </div>
                </div>
                :
                null
            }
        </BottomBar>
    </div>
}

export default SimpleMode
