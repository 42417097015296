import React from 'react'
import SizesList from "./SizesList";
import ColorsList from "./ColorsList";
import WishlistButton from "./WishlistButton";
import {printProductDisplayPrice} from "../../utils/priceUtils";
import {ReactComponent as PlusSizeSvg} from '../assets/plus-size.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './ProductItem.scss'
import Loader from "../Common/Loader";
import {Link} from "react-router-dom";

import moment from "moment";
import {cloudflareImage} from "../../utils/utils";

const ExtraMeta = ({item, price, regularPrice, currencySymbol, showLoader, comingSoonProducts, preorder}) => {
    let sizes = new Set()
    let sizesWithStock = new Set()
    let colors = {}

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('factory');

    const hidePrices = localStorage.getItem('vamp_app_hide_prices')

    console.log('here')

    item.variations.filter(variation => {
        sizes.add(variation.sizeName)

        if(comingSoonProducts) {
            sizesWithStock.add(variation.sizeName)

            if (!colors[variation.colorCode]) {
                colors[variation.colorCode] = {
                    hex: variation.colorHex,
                    name: variation.colorName
                }
            }
        }else {
            if(variation.stock || preorder) {
                sizesWithStock.add(variation.sizeName)

                let isSimoneSeasonCorrect = true

                if( myParam === 'Simone' ){
                    const selectedSeason = localStorage.getItem("selectedSeason");
                    if( selectedSeason !== variation.season && variation.season !== "0000" ){
                        isSimoneSeasonCorrect = false;
                    }
                }

                if (!colors[variation.colorCode] && isSimoneSeasonCorrect) {
                    colors[variation.colorCode] = {
                        hex: variation.colorHex,
                        name: variation.colorName
                    }
                }
            }
        }
    })

    let deliveryDate = false

    if(comingSoonProducts) {
        deliveryDate = moment(item.deliveryDate).format('D/M/Y');
    }



    return <div className="extra-meta">
        {/*{(item.length || item.front || item.pants) ? <div className="size-attributes">*/}
        {/*    {item.length ? <span>{item.length}cm</span> : null}*/}
        {/*    {item.front ? <span>{item.front}</span> : null}*/}
        {/*    {item.pants ? <span>{item.pants}</span> : null}*/}
        {/*</div> : null}*/}
        <div className="item-name">
            {item.titleEN}
        </div>
        {comingSoonProducts ? <div className="delivery-date">{deliveryDate}</div> : null}
        <div className="colors">
            <ColorsList colors={Object.values(colors)}/>
        </div>
        {
            myParam !== 'Simone' &&
            <div className="sizes">
                <SizesList sizes={Array.from(sizesWithStock)} thin={item.markedAsPreorder}/>
            </div>
        }

        <ItemPrice
            hidePrices={hidePrices}
            price={price}
            regularPrice={regularPrice}
            currencySymbol={currencySymbol}
            showLoader={showLoader}
            item={item}
        />
    </div>
}

const ItemPrice = ({hidePrices, price, regularPrice, currencySymbol, showLoader, item}) => {
    if (!price || hidePrices) {
        return null;
    }

    if (showLoader) {
        return <Loader/>
    }

    if (price === regularPrice) {
        return <div className="item-price">
            <span>{currencySymbol}{price}</span>
        </div>
    }

    if (price !== regularPrice && parseFloat(regularPrice) > parseFloat(price)) {

        let discountPercent = (parseFloat(price) - parseFloat(regularPrice)) / parseFloat(regularPrice) * 100;

        return <div className="item-price with-sale">
            <span className="regular-price">{currencySymbol}{regularPrice}</span>
            <span>{currencySymbol}{price}</span>
            {
                (item && item.price && item.price.sale && parseFloat(item.price.sale) > 0) ?
                    <div className="product-in-sale-bubble">
                        <div className="percent">
                            {parseInt(item.price.sale)}%
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    }

    return null
}

const ProductItem = ({
                         item,
                         extraMeta,
                         handleClick,
                         wishlistToggle,
                         isInWishlist,
                         currencySymbol,
                         price,
                         regularPrice,
                         showLoader,
                         preorder,
                         wishlist,
                         disabledBuy,
                         comingSoonProducts,
                         itemsPerLine
                     }) => {

    let productLink = (preorder || wishlist) ? `/app/presentation/product/${item._id}` : `/app/product/${item._id}`

    if(disabledBuy) {
        productLink = false
    }

    return <article className={`product-item product-item-${item._id}`}>
        {/*item.plussize ? <span className="plus-size-icon"><PlusSizeSvg/></span> : null*/}
        {/* ( item.productCollection === "HOMEWEAR" && item.markedAsPreorder ) && <img className={'web-only-svg'} src='/webonly.svg'/>  */}
        {item.markedAsPreorder ?
            <WishlistButton
                handleWishlistClick={() => wishlistToggle(item._id, 'product')}
                isInWishlist={isInWishlist}
            />
            : null}
        {productLink ?
            <Link to={productLink}>
                <LazyLoadImage
                    className="product-image"
                    src={parseInt(itemsPerLine) < 4 ? cloudflareImage(item.image, 655) : cloudflareImage(item.image, 320)}
                    alt={item.titleEN}
                    onClick={handleClick}
                />
                <div className="product-meta">
                    <div className="product-code">
                        {item.code}
                    </div>
                    {extraMeta ? <ExtraMeta
                        item={item}
                        price={price}
                        regularPrice={regularPrice}
                        currencySymbol={currencySymbol}
                        showLoader={showLoader}
                        comingSoonProducts={comingSoonProducts}
                        preorder={preorder}
                    /> : null}
                </div>
            </Link>
            :
            <div>
                <LazyLoadImage
                    className="product-image"
                    src={parseInt(itemsPerLine) < 4 ? cloudflareImage(item.image, 655) : cloudflareImage(item.image, 320)}
                    alt={item.titleEN}
                    onClick={handleClick}
                />
                <div className="product-meta">
                    <div className="product-code">
                        {item.code}
                    </div>
                    {extraMeta ? <ExtraMeta
                        item={item}
                        price={price}
                        regularPrice={regularPrice}
                        currencySymbol={currencySymbol}
                        showLoader={showLoader}
                        comingSoonProducts={comingSoonProducts}
                    /> : null}
                </div>
            </div>
        }

    </article>
}

export default ProductItem
